export const extractCompanyFromUrl = (url) => {
  if (!url) return ''

  const regex = /https?:\/\/([^.]+)(\.staging|\.dev)?\.proton\.ai.*/
  const companyInstance = url.match(regex)
  if (companyInstance && companyInstance.length > 1) {
    return companyInstance[1]
  }
  return ''
}

export const buildRedirectUrl = (company) => {
  if (!company) return '/'

  let url = window.location.host
  url = url.replace('janus', company)

  return window.location.protocol + '//' + url
}